<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col md="4" lg="4" xl="3" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper d-flex justify-center">
            <div class="d-flex align-center px-10 px-lg-12 flex-column justify-center flex-grow-1">
              <v-img
                contain
                max-width="453px"
                width="100%"
                class="flex-grow-0 mb-5"
                :src="require(`@/assets/images/login/login_illustration_experts.svg`)"
              ></v-img>
              <p class="text-center accent--text text-xl">bAV Portal</p>
            </div>
          </div>
        </v-col>

        <v-col md="8" lg="8" xl="9" cols="12" class="d-flex auth-bg-v2 pb-0 flex-column">
          <div class="d-flex flex-column">
            <AppBarI18n />
          </div>
          <div class="d-flex flex-grow-1 align-center">
            <v-card class="auth-card" color="transparent">
              <!-- logo -->
              <v-card-title class="d-flex align-center justify-center py-7">
                <v-img :src="appLogo" max-width="375px" alt="logo" contain></v-img>
              </v-card-title>
              <v-card-text>
                <p class="font-weight-bold text--secondary mb-3 text-left text-xl">
                  {{ $t('loginWelcome') }}
                </p>
                <p v-if="$route.name === 'welcome-login'" class="mb-0 text-sm">
                  {{ $t('loginWelcomeToken') }}
                </p>
                <p v-else class="mb-0 text-left text-sm">
                  {{ $t('loginWelcomeMsg') }}
                </p>
              </v-card-text>

              <v-card-text v-if="loginError" class="mb-0">
                <v-alert dense text border color="error" class="mb-2">
                  <!--<strong>-->
                  {{ $t(loginError) }}
                  <!--</strong>-->
                </v-alert>
              </v-card-text>

              <!-- login form -->
              <v-card-text>
                <v-form ref="loginForm" @submit.prevent="handleSubmit">
                  <v-text-field
                    v-model="email"
                    outlined
                    :label="$t('loginEmail')"
                    :placeholder="$t('loginEmail')"
                    hide-details="auto"
                    :rules="[validators.required]"
                    class="mb-3 required"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    outlined
                    :rules="[validators.required]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('loginPassword')"
                    :placeholder="$t('loginPassword')"
                    :append-icon="isPasswordVisible ? icons.mdiEye : icons.mdiEyeOutline"
                    hide-details="auto"
                    class="required"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template></v-text-field
                  >

                  <div class="d-flex align-center justify-end flex-wrap">
                    <!-- forgot link -->
                    <router-link to="/forgot-password" class="mt-1 text-sm">
                      {{ $t('loginForgetPw') }}
                    </router-link>
                  </div>

                  <v-btn type="submit" block depressed color="primary" class="mt-3">
                    {{ $t('loginBtnText') }}
                  </v-btn>
                </v-form>
              </v-card-text>

              <!-- create new account  -->
              <v-card-text class="text-center flex-wrap mt-3 mb-3">
                <span class="mb-2 text-sm"> {{ $t('loginProblem') }} </span><br />
                <span>
                  <a href="mailto:support@degura.de" class="support-link primary--text text-sm mt-1">
                    {{ $t('loginSupportLink') }}
                  </a>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <Footer />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { required } from '@core/utils/validation';
import { mdiEyeOutline, mdiEyeOffOutline, mdiEye } from '@mdi/js';
import { ref, getCurrentInstance, computed, onMounted, watch } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { LOGIN_ACTION } from '../../store/modules/auth/actions';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: {
    Footer,
    AppBarI18n,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const loginForm = ref(null);
    const isPasswordVisible = ref(false);

    const email = ref('');
    const password = ref('');
    const acceptedCookies = ref(vm.$store.getters.necessaryCookies);

    const handleSubmit = () => {
      const isFormValid = loginForm.value.validate();

      if (!isFormValid) return;

      vm.$store.dispatch(LOGIN_ACTION, {
        email: email.value,
        password: password.value,
      });
    };

    const openUserlike = () => {
      vm.$userlike.userlikeStartChat();
    };

    watch(
      () => vm.$store.getters.necessaryCookies,
      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedCookies.value = true;
        } else {
          acceptedCookies.value = false;
        }
      },
    );

    onMounted(() => {
      if (vm.$route.name === 'welcome-login' && vm.$route.query.token) {
        vm.$router.push({ path: '/passwort-aendern', query: { ...vm.$route.query, ...vm.$route.params } });
      }

      vm.$store.commit('resetAuth');
      vm.$store.commit('setLoginError', null);
      vm.$store.commit('resetConsultant');
      if (vm.$userlike) {
        vm.$userlike.userlikeQuitChat();
      }
    });

    return {
      isPasswordVisible,
      email,
      password,
      loginForm,
      handleSubmit,
      openUserlike,
      acceptedCookies,
      loginError: computed(() => vm.$store.getters.loginError),

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiEye,
      },

      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    };
  },
};
</script>

<style lang="scss"></style>
